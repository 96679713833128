import { useContext } from "@nuxtjs/composition-api";

const tokenValidation = async (query) => {
  const context = useContext();

  try {
    const { data } = await context.app.$vsf.$magento.api.customQuery({ query: `
      mutation {
         validateToken(
           input: {
             id: ${query.value.id},
             ref: "${query.value.ref}",
             t: "${query.value.t}"
           }
         ) {
           is_valid
           message
         }
        }
      `
    });
    return data['validateToken'].is_valid;
  } catch (e) {
    console.warn('Something went wrong: ', e);
    return false;
  }
};

export default tokenValidation;
